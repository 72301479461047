import { defineNuxtPlugin } from '#app/nuxt'
import { LazyABTestingVersion, LazyAirportCarParksList, LazyAirportTabs, LazyAlert, LazyAwardBadge, LazyBanner, LazyBenefits, LazyBreadcrumbs, LazyButton, LazyCarousel, LazyCheckmarkList, LazyCompareTravelMoney, LazyComponentList, LazyContactFormSps, LazyCoreABComponentTesting, LazyCoreAccordion, LazyCoreAccordionList, LazyCoreAdvancedTitle, LazyCoreBanner, LazyCoreCountdownSection, LazyCoreDynamicComponents, LazyCoreEventComponent, LazyCoreFeatureList, LazyCoreFooter, LazyCoreLayout, LazyCoreLinkWrapper, LazyCorePage, LazyCorePropelProductCard, LazyCoreSimpleCard, LazyCoreSimpleImage, LazyCoreSimpleLink, LazyCoreSimpleRichText, LazyCoreSimpleTitle, LazyCoreVideo, LazyCountdownCodeReveal, LazyCountdownEmailSubscription, LazyCountdownEmailSubscriptionForm, LazyCountdownTimer, LazyCustomerReviews, LazyEmailSubscription, LazyEmailSubscriptionComponent, LazyFaqs, LazyFeefoReviews, LazyFooter, LazyFormInput, LazyFormSelect, LazyFormTextarea, LazyGeneralText, LazyHeader, LazyHero, LazyHeroCardLayout, LazyHeroCardLayoutHeader, LazyImageText, LazyLinkList, LazyLoungeProductCard, LazyLoungeProductsList, LazyMultiColumnsLayout, LazyMultiColumnsLayoutNew, LazyOTCookieButton, LazyOTCookieTables, LazyOffersSection, LazyOtNotice, LazyPage, LazyPageTitle, LazyPartners, LazyProductAddressList, LazyProductMap, LazyResendConfirmation, LazySPSAirportPage, LazySPSBusinessRegisterForm, LazySPSBusinessSearchbox, LazySPSHeroFooter, LazySPSProductHeader, LazySPSProductOverview, LazySPSSimpleSearch, LazySPSSimpleSearchPropel, LazySearchbox, LazySearchboxPropel, LazyShowMoreText, LazySignInModal, LazySimpleAirportProduct, LazySimpleAirportProductsList, LazySimpleImage, LazySimpleMap, LazySimpleParkingCoupon, LazySimpleRichtext, LazySimpleText, LazySubscribeModal, LazyTabbedSearchboxHero, LazyTable, LazyTextTitle, LazyTextTitleIconList, LazyTimelineText, LazyVideoText, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["ABTestingVersion", LazyABTestingVersion],
["AirportCarParksList", LazyAirportCarParksList],
["AirportTabs", LazyAirportTabs],
["Alert", LazyAlert],
["AwardBadge", LazyAwardBadge],
["Banner", LazyBanner],
["Benefits", LazyBenefits],
["Breadcrumbs", LazyBreadcrumbs],
["Button", LazyButton],
["Carousel", LazyCarousel],
["CheckmarkList", LazyCheckmarkList],
["CompareTravelMoney", LazyCompareTravelMoney],
["ComponentList", LazyComponentList],
["ContactFormSps", LazyContactFormSps],
["CoreABComponentTesting", LazyCoreABComponentTesting],
["CoreAccordion", LazyCoreAccordion],
["CoreAccordionList", LazyCoreAccordionList],
["CoreAdvancedTitle", LazyCoreAdvancedTitle],
["CoreBanner", LazyCoreBanner],
["CoreCountdownSection", LazyCoreCountdownSection],
["CoreDynamicComponents", LazyCoreDynamicComponents],
["CoreEventComponent", LazyCoreEventComponent],
["CoreFeatureList", LazyCoreFeatureList],
["CoreFooter", LazyCoreFooter],
["CoreLayout", LazyCoreLayout],
["CoreLinkWrapper", LazyCoreLinkWrapper],
["CorePage", LazyCorePage],
["CorePropelProductCard", LazyCorePropelProductCard],
["CoreSimpleCard", LazyCoreSimpleCard],
["CoreSimpleImage", LazyCoreSimpleImage],
["CoreSimpleLink", LazyCoreSimpleLink],
["CoreSimpleRichText", LazyCoreSimpleRichText],
["CoreSimpleTitle", LazyCoreSimpleTitle],
["CoreVideo", LazyCoreVideo],
["CountdownCodeReveal", LazyCountdownCodeReveal],
["CountdownEmailSubscription", LazyCountdownEmailSubscription],
["CountdownEmailSubscriptionForm", LazyCountdownEmailSubscriptionForm],
["CountdownTimer", LazyCountdownTimer],
["CustomerReviews", LazyCustomerReviews],
["EmailSubscription", LazyEmailSubscription],
["EmailSubscriptionComponent", LazyEmailSubscriptionComponent],
["Faqs", LazyFaqs],
["FeefoReviews", LazyFeefoReviews],
["Footer", LazyFooter],
["FormInput", LazyFormInput],
["FormSelect", LazyFormSelect],
["FormTextarea", LazyFormTextarea],
["GeneralText", LazyGeneralText],
["Header", LazyHeader],
["Hero", LazyHero],
["HeroCardLayout", LazyHeroCardLayout],
["HeroCardLayoutHeader", LazyHeroCardLayoutHeader],
["ImageText", LazyImageText],
["LinkList", LazyLinkList],
["LoungeProductCard", LazyLoungeProductCard],
["LoungeProductsList", LazyLoungeProductsList],
["MultiColumnsLayout", LazyMultiColumnsLayout],
["MultiColumnsLayoutNew", LazyMultiColumnsLayoutNew],
["OTCookieButton", LazyOTCookieButton],
["OTCookieTables", LazyOTCookieTables],
["OffersSection", LazyOffersSection],
["OtNotice", LazyOtNotice],
["Page", LazyPage],
["PageTitle", LazyPageTitle],
["Partners", LazyPartners],
["ProductAddressList", LazyProductAddressList],
["ProductMap", LazyProductMap],
["ResendConfirmation", LazyResendConfirmation],
["SPSAirportPage", LazySPSAirportPage],
["SPSBusinessRegisterForm", LazySPSBusinessRegisterForm],
["SPSBusinessSearchbox", LazySPSBusinessSearchbox],
["SPSHeroFooter", LazySPSHeroFooter],
["SPSProductHeader", LazySPSProductHeader],
["SPSProductOverview", LazySPSProductOverview],
["SPSSimpleSearch", LazySPSSimpleSearch],
["SPSSimpleSearchPropel", LazySPSSimpleSearchPropel],
["Searchbox", LazySearchbox],
["SearchboxPropel", LazySearchboxPropel],
["ShowMoreText", LazyShowMoreText],
["SignInModal", LazySignInModal],
["SimpleAirportProduct", LazySimpleAirportProduct],
["SimpleAirportProductsList", LazySimpleAirportProductsList],
["SimpleImage", LazySimpleImage],
["SimpleMap", LazySimpleMap],
["SimpleParkingCoupon", LazySimpleParkingCoupon],
["SimpleRichtext", LazySimpleRichtext],
["SimpleText", LazySimpleText],
["SubscribeModal", LazySubscribeModal],
["TabbedSearchboxHero", LazyTabbedSearchboxHero],
["Table", LazyTable],
["TextTitle", LazyTextTitle],
["TextTitleIconList", LazyTextTitleIconList],
["TimelineText", LazyTimelineText],
["VideoText", LazyVideoText],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
